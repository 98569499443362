/*
================
 VARIABLES
================
*/
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.shadow-1 {
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); }

.jv-content .jv-content-item {
  margin-bottom: 20px; }  
  
@media only screen and (max-width: 1400px) {
  .mh-single-project-slide-by-side .owl-prev {
    top: 40%;
    left: 0; }

  .mh-single-project-slide-by-side .owl-next {
    top: 44%;
    right: 0; }
  }
  .podcast-frame {
    width: 600px;
  }
@media only screen and (max-width: 991px) {
  .mh-header {
    display: none;
  }
  
  .section-separator {
    padding: 40px 0; }

  .mh-home-2 .mh-header-info p {
    padding: 0 0px;
    font-weight: 300; }

  .home-section-separator {
    padding-top: 100px;
    padding-bottom: 100px; }

  .mh-footer-address .mh-address-footer-item .each-icon .fa {
    width: 60px;
    height: 60px;
    line-height: 60px; }

  .mh-home-2 .mh-header-info .mh-about-tag {
    width: 80%; }

  .mh-xs-mobile-nav {
    z-index: 99;
    /*-------------------------------*/
    /*            Overlay            */
    /*-------------------------------*/
    /*  Navigation Menu */ }
    .mh-xs-mobile-nav .nav-btn .navbar-toggler.active {
      display: none; }
    .mh-xs-mobile-nav .navbar-nav {
      padding-top: 50px; }
    .mh-xs-mobile-nav .navbar-collapse {
      position: fixed;
      top: 0;
      bottom: 0;
      width: 320px;
      left: 0;
      z-index: 990;
      margin-top: 0px;
      padding: 0;
      background-color: #0bceaf;
      transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
      -o-transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
      -ms-transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
      -moz-transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
      -webkit-transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
      -webkit-transition: all 0.4s ease-out;
      -moz-transition: all 0.4s ease-out;
      -o-transition: all 0.4s ease-out;
      -ms-transition: all 0.4s ease-out;
      transition: all 0.4s ease-out;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      overflow-y: none; }
    .mh-xs-mobile-nav .navbar-collapse.active {
      transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
      -o-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
      -ms-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
      -moz-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
      -webkit-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1); }
    .mh-xs-mobile-nav .collapse {
      display: block; }
    .mh-xs-mobile-nav .navbar-fixed-bottom .navbar-collapse,
    .mh-xs-mobile-nav .navbar-fixed-top .navbar-collapse {
      max-height: inherit; }
    .mh-xs-mobile-nav .navbar-header {
      position: fixed;
      z-index: 999;
      width: 100%;
      margin-top: 0px;
      left: 0; }
    .mh-xs-mobile-nav .navbar-toggler .icon {
      transform: translateX(-50%) translateY(-50%) translateZ(0) rotate(0deg) scale(1);
      -o-transform: translateX(-50%) translateY(-50%) translateZ(0) rotate(0deg) scale(1);
      -ms-transform: translateX(-50%) translateY(-50%) translateZ(0) rotate(0deg) scale(1);
      -moz-transform: translateX(-50%) translateY(-50%) translateZ(0) rotate(0deg) scale(1);
      -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) rotate(0deg) scale(1); }
    .mh-xs-mobile-nav .navbar-toggler .icon,
    .mh-xs-mobile-nav .navbar-toggler .icon::after,
    .mh-xs-mobile-nav .navbar-toggler .icon::before {
      width: 22px;
      height: 2px;
      position: absolute;
      border-radius: 2px;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden; }
    .mh-xs-mobile-nav .navbar-toggler .icon::after,
    .mh-xs-mobile-nav .navbar-toggler .icon::before {
      content: '';
      top: 0;
      right: 0;
      -webkit-transition: all 0.6s ease-out;
      -moz-transition: all 0.6s ease-out;
      -o-transition: all 0.6s ease-out;
      -ms-transition: all 0.6s ease-out;
      transition: all 0.6s ease-out;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
    .mh-xs-mobile-nav .navbar-toggler .icon::after {
      transform: translateX(0) translateY(6px) translateZ(0) rotate(0deg) scale(1);
      -o-transform: translateX(0) translateY(6px) translateZ(0) rotate(0deg) scale(1);
      -ms-transform: translateX(0) translateY(6px) translateZ(0) rotate(0deg) scale(1);
      -moz-transform: translateX(0) translateY(6px) translateZ(0) rotate(0deg) scale(1);
      -webkit-transform: translateX(0) translateY(6px) translateZ(0) rotate(0deg) scale(1); }
    .mh-xs-mobile-nav .navbar-toggler .icon::before {
      transform: translateX(0) translateY(-6px) translateZ(0) rotate(0deg) scale(1);
      -o-transform: translateX(0) translateY(-6px) translateZ(0) rotate(0deg) scale(1);
      -ms-transform: translateX(0) translateY(-6px) translateZ(0) rotate(0deg) scale(1);
      -moz-transform: translateX(0) translateY(-6px) translateZ(0) rotate(0deg) scale(1);
      -webkit-transform: translateX(0) translateY(-6px) translateZ(0) rotate(0deg) scale(1); }
    .mh-xs-mobile-nav .navbar-toggler.active .icon::after {
      transform: translateX(0) translateY(0) translateZ(0) rotate(138deg) scale(1);
      -o-transform: translateX(0) translateY(0) translateZ(0) rotate(138deg) scale(1);
      -ms-transform: translateX(0) translateY(0) translateZ(0) rotate(138deg) scale(1);
      -moz-transform: translateX(0) translateY(0) translateZ(0) rotate(138deg) scale(1);
      -webkit-transform: translateX(0) translateY(0) translateZ(0) rotate(138deg) scale(1); }
    .mh-xs-mobile-nav .navbar-toggler.active .icon::before {
      transform: translateX(0) translateY(0) translateZ(0) rotate(-135deg) scale(1);
      -o-transform: translateX(0) translateY(0) translateZ(0) rotate(-135deg) scale(1);
      -ms-transform: translateX(0) translateY(0) translateZ(0) rotate(-135deg) scale(1);
      -moz-transform: translateX(0) translateY(0) translateZ(0) rotate(-135deg) scale(1);
      -webkit-transform: translateX(0) translateY(0) translateZ(0) rotate(-135deg) scale(1); }
    .mh-xs-mobile-nav .navbar-toggler.active .icon::after,
    .mh-xs-mobile-nav .navbar-toggler.active .icon::before {
      background-color: #fff; }
    .mh-xs-mobile-nav .navbar-toggler.active .icon {
      background-color: transparent; }
    .mh-xs-mobile-nav .navbar-toggler:focus {
      outline: 0 !important; }
    .mh-xs-mobile-nav .strict {
      background-color: rgba(0, 0, 0, 0.9);
      padding-bottom: 65px; }
    .mh-xs-mobile-nav .navbar-toggler {
      padding: 15px;
      margin-right: 15px;
      margin-left: 15px;
      margin-top: 30px;
      z-index: 1000;
      -webkit-transition: all 0.4s ease-out;
      -moz-transition: all 0.4s ease-out;
      -o-transition: all 0.4s ease-out;
      -ms-transition: all 0.4s ease-out;
      transition: all 0.4s ease-out;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      display: block; }
    .mh-xs-mobile-nav .navbar-header .navbar-toggler .icon-bar {
      background-color: #0bceaf;
      -webkit-transition: all 0.4s ease-out;
      -moz-transition: all 0.4s ease-out;
      -o-transition: all 0.4s ease-out;
      -ms-transition: all 0.4s ease-out;
      transition: all 0.4s ease-out;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
    .mh-xs-mobile-nav .navbar-header .navbar-toggler.active .icon-bar {
      background-color: #fff; }
    .mh-xs-mobile-nav .overlay {
      position: fixed;
      display: none;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.8);
      z-index: 3; }
    .mh-xs-mobile-nav .overlay.active {
      display: block; }
    .mh-xs-mobile-nav .navbar-collapse {
      position: fixed;
      top: 0;
      bottom: 0;
      width: 320px;
      left: 0;
      z-index: 990;
      margin-top: 0px;
      padding: 0;
      background-color: #0bceaf;
      transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
      -o-transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
      -ms-transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
      -moz-transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
      -webkit-transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
      -webkit-transition: all 0.4s ease-out;
      -moz-transition: all 0.4s ease-out;
      -o-transition: all 0.4s ease-out;
      -ms-transition: all 0.4s ease-out;
      transition: all 0.4s ease-out;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      overflow-y: none; }
    .mh-xs-mobile-nav .navbar-collapse .nav {
      margin-top: 75px;
      border-top: 1px solid;
      border-top-color: rgba(255, 255, 255, 0.1);
      padding: 20px; }
    .mh-xs-mobile-nav .navbar-collapse .nav li {
      text-align: center;
      margin-bottom: 0px;
      width: 100%;
      display: block;
      -webkit-transition: all 0.4s ease-out;
      -moz-transition: all 0.4s ease-out;
      -o-transition: all 0.4s ease-out;
      -ms-transition: all 0.4s ease-out;
      transition: all 0.4s ease-out;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      transform: translate3d(-20px, -10px, 0); }
    .mh-xs-mobile-nav .navbar-collapse .nav li a {
      background-color: transparent;
      text-transform: uppercase;
      font-size: 17px;
      line-height: 26px; }
    .mh-xs-mobile-nav .navbar-collapse .nav li a:hover {
      color: white !important;
      background: transparent; }
    .mh-xs-mobile-nav .navbar-collapse .nav li a.active {
      color: #fff; }
    .mh-xs-mobile-nav .navbar-collapse .nav li.active {
      color: #fff; }
    .mh-xs-mobile-nav .navbar-collapse.active {
      transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
      -o-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
      -ms-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
      -moz-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
      -webkit-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1); }
    .mh-xs-mobile-nav .collapse.in {
      background-color: rgba(0, 0, 0, 0.9); }
    .mh-xs-mobile-nav .navbar-dark .navbar-toggler {
      border-color: transparent; }
    .mh-xs-mobile-nav .navbar-dark .navbar-toggler-icon {
      background-image: inherit; }

  .mh-home .mh-header-info {
    padding: 0; }
  .home-padding {
    padding: 100px 0; }

  .mh-about .mh-about-inner {
    padding-left: 5px; }

  .mh-home .mh-header-info h2 {
    font-size: 42px;
    font-weight: 600;
    line-height: 54px; }
  .mh-home .mh-header-info h4 {
    font-size: 20px;
    line-height: 24px; }

  .mh-nav {
    padding: 0; }

  .mh-professional-skills {
    padding-left: 0; }

  .mh-skills .mh-professional-skill {
    padding-right: 0; }

  .mh-home .mh-header-info .mh-promo {
    margin-bottom: 20px; }

  .nav-strict .navbar-toggler {
    margin-top: 0px; }

  .mh-footer-3 .mh-footer-address .mh-address-footer-item .each-info {
    padding-left: 20px; }

  .mh-blog-sidebar .social-icon li {
    margin-right: 2px; }

  .mh-xs-mobile-nav .navbar-nav {
    padding-top: 50px;
    padding-right: 20px; 
  }
  .podcast-frame {
    width: 500px;
  } 
  
}
@media only screen and (max-width: 768px) {
  .mh-blog .mh-blog-item {
    text-align: center; }

  .blog-form-inner {
    padding: 10px; }

  .mh-blog .mh-blog-item .blog-inner {
    padding: 0px; }

  .mh-blog-next-prev-post {
    text-align: center !important;
    margin: 10px 0; }

  .mh-blog .mh-blog-item img {
    margin-bottom: 30px;
    margin: 0 auto;
    float: none;
    text-align: center;
    margin-bottom: 20px;
    width: 100%; }

  .mh-blog .mh-blog-item h2 {
    margin-bottom: 14px;
    font-weight: bold;
    font-size: 18px;
    line-height: 30px; }

  .mh-experince h3 {
    margin-bottom: 30px; }

  .mh-work h3 {
    margin-bottom: 30px;
    margin-top: 30px; }

  .mh-work {
    padding-left: 0; }

  .mh-education {
    padding-right: 0; }

  .mh-skills .mh-professional-skill h3 {
    margin-bottom: 30px; }

  .mh-professional-skills h3 {
    margin-top: 30px;
    margin-bottom: 30px; }

  .mh-about .mh-about-inner {
    margin-top: 40px; }

  .mh-footer-address .mh-address-footer-item .each-icon .fa {
    width: 70px;
    height: 70px;
    line-height: 70px; }

  .mh-home-2 .mh-header-info ul.mh-home-contact li {
    display: inline-block;
    margin: 10px 15px;
    width: 40%;
    text-align: left; }

  .mh-testimonial .mh-client-item {
    padding: 30px 10px; }

  .mh-home-2 .mh-header-info .mh-about-tag {
    width: 100%; }

  .mh-blog .mh-blog-item .blog-inner {
    text-align: left; }

  .single-comment {
    margin: 0 auto;
    text-align: center;
    display: block;
    margin-top: 30px; }
    .single-comment img {
      margin-bottom: 20px; }

  .mh-blog .mh-blog-item .blog-inner {
    text-align: right;
    padding: 0 12px; 
  }
  .podcast-frame {
    width: 400px;
  } 
  
  .social-icon li {
    margin-right: 12px;
    margin-left: 12px; }
  .social-icon li a i {
    font-size: 2em;}
}
@media only screen and (max-width: 575px) {
  
  .xs-column-reverse {
    display: flex;
    flex-direction: column-reverse; }

  .mh-header {
    padding: 0; }

  .home-padding {
    padding: 100px 0; }

  .mh-home .hero-img {
    margin-bottom: 30px; }

  .mh-home .mh-header-info {
    padding: 0;
    text-align: center;
    margin-top: 40px; }

  .mh-about .mh-about-inner {
    padding-left: 5px;
    text-align: center;
    margin: 30px 15px; }

  .mh-skills .mh-professional-skill {
    padding-right: 0;
    padding: 0 15px; }

  .mh-experince h3 {
    margin-bottom: 30px;
    text-align: center; }

  .mh-education {
    margin-bottom: 30px;
    padding-right: 0; }

  .mh-work {
    padding-left: 0;
    margin-bottom: 30px; }

  .portfolio-nav ul li {
    margin-bottom: 20px; }

  .mh-skills .mh-professional-skill {
    margin-bottom: 50px; }

  .section-separator {
    padding: 30px 0; }

  .mh-home .mh-header-info .mh-promo {
    margin-bottom: 20px; }

  .mh-featured-project-content {
    margin-top: 40px;
    text-align: center; }

  .mh-featured-project .mh-featured-item .mh-featured-project-content p {
    text-align: center; }

  .mh-quates .each-quates {
    padding: 30px; }

  .mh-map {
    padding: 0px;
    margin-top: 40px; }

  .mh-home-2 .mh-header-info ul.mh-home-contact li {
    width: 100%;
    margin: 6px 10px; }

  .mh-footer-3 .mh-footer-address .mh-address-footer-item {
    display: block;
    padding: 20px 0px; }

  .mh-footer-3 .mh-footer-address .mh-address-footer-item .each-info {
    padding-left: 20px;
    text-align: center; }

  .mh-footer-3 .mh-footer-address .mh-address-footer-item .each-info {
    padding-left: 0;
    text-align: center; }

  .xs-no-padding {
    padding: 0; }

  .mh-home-2 .mh-header-info {
    text-align: center;
    padding-top: 50px; }

  .mh-portfolio-modal-inner {
    margin-right: 0; }

  .mh-portfolio-modal {
    padding: 10px; }

  .mh-portfolio-modal-img {
    margin-top: 50px; }

  .social-icon {
    display: block;
    margin: 0 auto;
    float: none;
    text-align: center; }
    .social-icon li {
      list-style: none;
      float: none;
      text-align: center;
      display: inline-block;
      margin-bottom: 0px;
      margin-right: 4px;
      margin-left: 4px; }
    .social-icon li a i {
      font-size: 1.8em;}

  .text-left.text-xs-center {
    text-align: center !important; 
  }
  .podcast-frame {
    width: 250px;
  } 
}
@media only screen and (max-width: 420px) {
  .mh-home .img-border {
    height: 280px;
    width: 280px; }

  .home-padding {
    padding: 60px 0; 
  }
  .podcast-frame {
    height: 102px;
    width: 250px;
  }
}
