body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*------------- 
[Table of contents]

[ DEFULT INDEX NUMBER ]

1.  GENERAL CSS
2.  NAVBAR
4.  HOME
5.  SERVICE
6.  ABOUT
7.  TESTIMONIAL
8.  FAQ
9.  QUATE
10. SUBSCRIBE
11. PRICING
12. BLOG
13. FOOTER
14. COPYRIGHT


-------------------------------------------------------------------*/
/*------------------------------------------------------------------
[ Color codes ]
    
    [ Text and Background Color ]
        Main color 1:       #1c93e5     
        Main color 2:       #009688      
        Main color 3:       #9c27b0
        Main color 4:       #ff4081      
        Background 1:       #FFFFFF            
        Background 2:       #F6F6F6           
        Shadow color:       #000000;            

        
-------------------------------------------------------------------*/
/*------------------------------------------------------------------
[Typography]

    [ There are using two different font typography ]
        Typography 1: Roboto font;
        Typography 2: Montserrat font;

    [ Other Typography style are same ]
    Body :
        font-style:     normal;
        font-size:      15px;

    h2:
        font-size: 48px;
        line-height: 55px;

    h3:
        font-size: 30px;
        line-height: 40px;

    h4:
        font-size: 20px;
        font-weight: 600;

    p:  
        font-size: 18px;
        line-height: 30px;


-------------------------------------------------------------------*/
/*
================
 VARIABLES
================
*/



* {
  /* CSS MAIN HEX */
  
  --black: #040303ff;
  --gunmetal: #222831ff;
  --onyx: #393e46ff;
  --blue-ncs: #0092caff;
  --cultured: #eeeeeeff;
  --crimson: #da0037ff;
  
  
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  
  .shadow-1 {
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); }
  
  /**
   * -------------------
   *  Defult CSS 
   * -------------------
   */
  html {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased; }
  
  body {
    font-family: "roboto", sans-serif;
    font-size: 14px;
    font-style: none;
    line-height: 24px;
    font-weight: 500;
    overflow-x: hidden !important;
    text-align: left;
    position: relative;
    color: #999999;
    opacity: 0.9; }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: "roboto", sans-serif; }
  
  p {
    opacity: 0.9; }
  
  .nav li a {
    text-decoration: none !important;
    display: inline-block;
    cursor: pointer;
    padding: 0px;
    background-color: transparent;
    -moz-transition: color 0.3s ease;
    -o-transition: color 0.3s ease;
    -webkit-transition: color 0.3s ease;
    transition: color 0.3s ease; }
    .nav li a:hover {
      background-color: transparent; }
    .nav li a:focus {
      background-color: transparent;
      outline: 0 !important; }
  
  a {
    text-decoration: none !important;
    cursor: pointer;
    padding: 0px;
    background-color: transparent;
    -moz-transition: color 0.3s ease;
    -o-transition: color 0.3s ease;
    -webkit-transition: color 0.3s ease;
    transition: color 0.3s ease;
    color: #000;
    opacity: 0.9; }
    a:hover {
      background-color: transparent; }
    a:focus {
      background-color: transparent;
      outline: 0 !important; }
  
  .center {
    float: none;
    margin: 0 auto;
    text-align: center; }
  
  /**
   * ---------------------
   *  Defult Halping Class
   * ---------------------
   */
  .overflow {
    overflow: hidden; }
  
  .relative {
    position: relative; }
  
  .display-table {
    display: table;
    width: 100%;
    height: 100%; }
  
  .display-flex {
    display: flex;
    flex: 1; }
  
  .vertical-middle {
    display: table-cell;
    float: none;
    vertical-align: middle; }
  
  /**
   * --------------------------------------
   *  Video Section Background Defult Style
   * --------------------------------------
   */
  .section-video {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 100%;
    min-width: 100%;
    overflow: hidden;
    z-index: -1; }
    .section-video .bgvid {
      position: absolute;
      top: 0;
      left: 0;
      min-height: 100%;
      min-width: 100%;
      background-size: cover;
      -o-background-size: cover;
      -moz-background-size: cover;
      -webkit-background-size: cover; }
  
  .body-video-bg > .section-video {
    position: fixed;
    top: 0;
    left: 0; }
  
  .section-title {
    text-align: center; }
    .section-title h3 {
      font-size: 30px;
      line-height: 40px;
      color: #fff;
      font-weight: bold;
      margin-bottom: 60px; }
  
  .new-page {
    padding-top: 100px;
  }
  
  /**
   * ------------------------------------
   *  Every Section Headline Defult Class
   * ------------------------------------
   */
  .section-heading {
    display: block;
    margin: 0; }
  
  .section-header {
    margin-bottom: 100px;
    text-align: center; }
    .section-header .sub-heading {
      font-size: 22px;
      line-height: 30px;
      font-weight: 300;
      text-transform: uppercase;
      margin: 0 0 10px 0; }
    .section-header.text-left,
    .section-header.text-left * {
      text-align: left; }
    .section-header.text-right,
    .section-header.text-right * {
      text-align: right; }
  
  .section-header-separator {
    position: relative;
    width: 145px;
    margin: 5px auto;
    display: inline-block; }
    .section-header-separator .icon {
      font-size: 48px;
      text-align: center !important; }
    .section-header-separator::before, .section-header-separator::after {
      content: '';
      position: absolute;
      width: 57px;
      height: 2px;
      top: 25px; }
    .section-header-separator::before {
      left: 0; }
    .section-header-separator::after {
      right: 0; }
  
  .v-middle {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: row;
    -webkit-flex-direction: row; }
  
  .mini-separator {
    padding: 20px 0; }
  
  
  /**
   * ---------------------
   *   Defult Button Style
   * ---------------------
   */
  .btn {
    -moz-transition: color 0.3s ease;
    -o-transition: color 0.3s ease;
    -webkit-transition: color 0.3s ease;
    transition: color 0.3s ease;
    outline: 0;
    padding: 8px 30px;
    font-weight: bold;
    border-radius: 20px; }
    .btn:last-child {
      margin-right: 0px !important; }
    .btn .icon {
      position: relative;
      top: 2px; }
      .btn .icon img {
        position: relative;
        top: -3px;
        width: 14px; }
    .btn.btn-fill {
      background-color: #0bceaf;
      color: #fff; }
    .btn.left-icon .icon {
      margin-right: 15px; }
    .btn.right-icon .icon {
      margin-left: 15px; }
    .btn.btn-border {
      background-color: transparent; }
    .btn.white-btn-border {
      background-color: transparent;
      border-color: #fff; }
    .btn.btn-round {
      padding: 15px;
      border-radius: 50%;
      width: 65px;
      height: 65px;
      font-size: 24px;
      text-align: center; }
      .btn.btn-round .icon {
        top: 7px; }
    .btn.apple, .btn.androad {
      -webkit-border-radius: 3px !important;
      -moz-border-radius: 3px !important;
      border-radius: 3px !important;
      padding: 9px 28px;
      height: 82px; }
      .btn.apple img, .btn.androad img {
        width: 35px;
        height: 100%;
        float: left; }
      .btn.apple .icon, .btn.androad .icon {
        font-size: 60px;
        float: left; }
      .btn.apple span, .btn.androad span {
        display: block;
        -moz-transition: color 0.3s ease;
        -o-transition: color 0.3s ease;
        -webkit-transition: color 0.3s ease;
        transition: color 0.3s ease; }
      .btn.apple .group, .btn.androad .group {
        float: right; }
      .btn.apple .big,
      .btn.apple .small, .btn.androad .big,
      .btn.androad .small {
        text-align: left;
        margin-left: 7px;
        line-height: 30px; }
      .btn.apple .big, .btn.androad .big {
        font-size: 24px;
        font-weight: 400; }
      .btn.apple .small, .btn.androad .small {
        font-size: 16px; }
  
  .btn.active.focus,
  .btn.active:focus,
  .btn.focus,
  .btn:active.focus,
  .btn:active:focus,
  .btn:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color !important;
    outline-offset: -2px; }
  
  .btn-text-link {
    padding: 50px 0 0 0;
    text-align: center; }
    .btn-text-link a {
      margin-left: 10px; }
  
  /** 
   * -----------------
   * Social Icon Style
   * -----------------
   */
  .social-icon {
    display: inline-block; 
    padding-bottom: 50px;}
    .social-icon li {
      list-style: none;
      float: left;
      margin-bottom: 0px;
      margin-right: 56px; }
      
      .social-icon li a {
        text-align: center;
        width: 45px;
        height: 45px;
        padding: 10px;
        border-radius: 50%; }
      .social-icon li:last-child a {
        margin-right: 0px; }
      .social-icon li a i {
        font-size: 3em;
      }
  /**
   * -----------------------------------
   * Subscription and contact  "MESSAGE"
   * -----------------------------------
   */
  .subscription-success,
  .subscription-failed,
  .email-success,
  .email-failed,
  .email-loading {
    font-size: 15px;
    display: none;
    text-align: center !important;
    padding: 10px !important; }
  
  .email-loading {
    color: #52B8FF; }
    .email-loading img {
      width: 15px;
      position: relative;
      top: -2px; }
  
  .subscription-failed,
  .email-failed {
    color: #FF5252 !important; }
    .subscription-failed .icon,
    .email-failed .icon {
      font-size: 20px;
      position: relative;
      top: 5px; }
  
  .subscription-success,
  .email-success {
    color: #56CC35; }
  
  .subscription-failed .icon,
  .email-failed .icon,
  .subscription-success .icon,
  .email-success .icon {
    font-size: 20px;
    position: relative;
    top: 5px; }
  
  /**
   * ------------------
   * list
   * ------------------
   */
  ul {
    margin: 0;
    padding: 0; }
    ul li {
      list-style-type: none; }
  
  /**
   * =================
   * CONTAINER HALF CONTENT
   * =================
   */
  .content-half {
    position: relative; }
  
  .container-half-left {
    left: 0;
    background-position: center right; }
  
  .container-half-right {
    right: 0;
    background-position: center left; }
  
  .vertical-middle-content {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: row;
    -webkit-flex-direction: row; 
    text-align: left;
  }
  
  /**
   * ---------------------------------
   *  Section Background Size Property
   * ---------------------------------
   */
  .bg-cover {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: 50% 50%;
    background-attachment: initial;
    background-repeat: no-repeat; }
  
  .dark-bg {
    background-color: #222831; }
  
  .section-separator {
    padding: 100px 0; }
  
  .home-section-separator {
    padding-top: 100px;
    padding-bottom: 200px; }
  
  .bg-black {
    background-color: #000; }
  
  .form-control {
    -webkit-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.16), 0 0px 2px 0 rgba(255, 255, 255, 0.12);
    -moz-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.16), 0 0px 2px 0 rgba(255, 255, 255, 0.12);
    box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.16), 0 0px 2px 0 rgba(255, 255, 255, 0.12);
    background-color: transparent;
    border-color: transparent;
    margin-bottom: 20px;
    height: 50px;
    line-height: 50px; }
    .form-control:focus {
      -webkit-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.16), 0 0px 2px 0 rgba(255, 255, 255, 0.12);
      -moz-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.16), 0 0px 2px 0 rgba(255, 255, 255, 0.12);
      box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.16), 0 0px 2px 0 rgba(255, 255, 255, 0.12);
      background-color: transparent; }
  
  .p-200 {
    padding: 0 200px; }
  
  .img-overlay {
    /* background-image: url(../images/footer-bg.png); */
    background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0 auto;
    background-position: center center; }
  
  .image-bg {
    background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0 auto;
    background-position: center center; }
  
  .home-2-img {
    background-image: url(../../public/images/home-bg-img.jpg); }
  
  .featured-img-one {
    background-image: url(../../public/images/home-bg-img.jpg); }
  
  .featured-img-two {
    /* background-image: url(../images/extra-feature-bg.jpg); } */
  }
  .map-image {
    background-image: url(../../public/images/map-color-overlay.png); }
  
  .mt-30 {
    margin-top: 30px; }
  
  button {
    outline: 0; }
    button:hover {
      outline: 0; }
    button:focus {
      outline: 0; }
  
  .btn {
    outline: 0; }
    .btn:hover {
      outline: 0; }
    .btn:focus {
      outline: 0; }
  
  .round-image {
    border-radius: 50%; }
  
  .form-control {
    padding: 20px;
    -webkit-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
    -moz-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
    box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8); }
    .form-control:focus {
      -webkit-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
      -moz-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
      box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
      border-color: transparent; }
  
  .contact-message {
    -webkit-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
    -moz-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
    box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
    background-color: transparent;
    border-color: transparent;
    margin-bottom: 20px;
    width: 100%;
    padding: 20px;
    outline: 0; }
    .contact-message:focus {
      -webkit-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
      -moz-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
      box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
      border-color: transparent; }
  
  /** 
   * -----------------
   * General CSS
   * -----------------
   */
  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-weight: bold; }
  
  .dark-version {
    opacity: 0.9; }
    .dark-version.black-bg {
      background-color: #040303; }
    .dark-version .section-loader {
      background: #040303; }
    .dark-version ::placeholder {
      color: #fff; }
    .dark-version a {
      color: #fff; }
    .dark-version h2 {
      color: #fff; }
    .dark-version h3 {
      color: #fff; }
    .dark-version h4 {
      color: #fff; }
    .dark-version h5 {
      color: #fff; }
    .dark-version h6 {
      color: #fff; }
    .dark-version p {
      color: #fff; }
    .dark-version address {
      color: #fff; }
    .dark-version span {
      color: #fff; }
    .dark-version div {
      color: #fff; }
    .dark-version ul li {
      color: #fff; }
    .dark-version .form-control {
      color: #fff; }
    .dark-version .contact-message {
      color: #fff; }
    .dark-version .nav-strict {
      background-color: #202026; }
    .dark-version .img-color-overlay {
      background-color: rgba(0, 0, 0, 0.8); }
    .dark-version .mh-home .mh-header-info .social-icon li .fa:hover {
      background-color: #fff; }
    .dark-version .page-item.active .page-link {
      background-color: #fff;
      border-color: #fff;
      color: #0bceaf; }
    .dark-version .navbar-toggler .icon,
    .dark-version .navbar-toggler .icon::after,
    .dark-version .navbar-toggler .icon::before {
      background-color: #fff; }
    .dark-version .mh-header .navbar-nav li.active a {
      border-color: #fff;
      padding-right: 0;
      padding-left: 0; }
    .dark-version .mh-header.nav-3 .navbar-nav li.active a {
      border-color: transparent; }
    .dark-version .mh-footer .form-control {
      -webkit-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
      -moz-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
      box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8); }
      .dark-version .mh-footer .form-control:focus {
        -webkit-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
        -moz-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
        box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8); }
    .dark-version .mh-footer .contact-message {
      -webkit-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
      -moz-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
      box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8); }
      .dark-version .mh-footer .contact-message:focus {
        -webkit-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
        -moz-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
        box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8); }
    .dark-version .mh-footer-2 .form-control {
      -webkit-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
      -moz-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
      box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8); }
      .dark-version .mh-footer-2 .form-control:focus {
        -webkit-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
        -moz-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
        box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8); }
    .dark-version .mh-footer-2 .contact-message {
      -webkit-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
      -moz-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
      box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8); }
      .dark-version .mh-footer-2 .contact-message:focus {
        -webkit-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
        -moz-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
        box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8); }
    .dark-version .mh-work .mh-experience-deatils .mh-work-item {
      -webkit-box-shadow: -1rem 0 3rem #000;
      box-shadow: -1rem 0 3rem #000; }
    .dark-version .mh-pricing .mh-pricing {
      -webkit-box-shadow: -1rem 0 3rem #000;
      box-shadow: -1rem 0 3rem #000; }
    .dark-version .mh-blog .mh-blog-item {
      -webkit-box-shadow: -1rem 0 3rem #000;
      box-shadow: -1rem 0 3rem #000; }
    .dark-version .mh-footer-address .mh-address-footer-item {
      -webkit-box-shadow: -1rem 0 3rem #000;
      box-shadow: -1rem 0 3rem #000; }
    .dark-version .mh-experince .mh-education-deatils .mh-education-item {
      -webkit-box-shadow: -1rem 0 3rem #000;
      box-shadow: -1rem 0 3rem #000; }
    .dark-version .jv-content .jv-content-item {
      -webkit-box-shadow: -1rem 0 3rem #000;
      box-shadow: -1rem 0 3rem #000; }
    .dark-version .mh-home .hero-img img {
      border: 20px solid rgba(255, 255, 255, 0.7); }
    .dark-version .candidatos .parcial .progressBar {
      background: rgba(199, 198, 198, 0.6); }
    .dark-version .mh-progress path:nth-child(1) {
      stroke: rgba(255, 255, 255, 0.5); }
  
  .mh-rtl {
    text-align: right; }
    .mh-rtl .mh-work .mh-experience-deatils
    .mh-work-item .work-responsibility
    li .fa {
      margin-left: 10px; }
    .mh-rtl .mh-blog-post-info ul li strong {
      margin-left: 6px; }
    .mh-rtl .mh-footer .form-control {
      text-align: right; }
    .mh-rtl .mh-footer .contact-message {
      text-align: right; }
  
  body {
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -webkit-transition: 0.3s;
    transition: 0.3s; }
  
  /*
      ==============
      Navigation
      ==============
  */
  /*---------------------------------------------------------------------*/
  /* Loader 
  /*---------------------------------------------------------------------*/
  .section-loader {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    z-index: 9999999; }
  
  .section-loader .loader {
    position: relative; }
  
  .section-loader .loader div {
    box-sizing: content-box;
    position: absolute;
    border-width: 4px;
    border-style: solid;
    opacity: 1;
    border-radius: 50%;
    -webkit-animation: loader 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    animation: loader 1s cubic-bezier(0, 0.2, 0.8, 1) infinite; }
  
  .section-loader .loader div:nth-child(1) {
    border-color: #E91E63; }
  
  .section-loader .loader div:nth-child(2) {
    border-color: #0dbda1;
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s; }
  
  .section-loader .loader {
    width: 200px !important;
    height: 200px !important;
    -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
    transform: translate(-100px, -100px) scale(1) translate(100px, 100px); }
  
  @keyframes loader {
    0% {
      top: 94px;
      left: 94px;
      width: 0;
      height: 0;
      opacity: 1; }
    100% {
      top: 57px;
      left: 57px;
      width: 74px;
      height: 74px;
      opacity: 0; } }
  @-webkit-keyframes loader {
    0% {
      top: 94px;
      left: 94px;
      width: 0;
      height: 0;
      opacity: 1; }
    100% {
      top: 57px;
      left: 57px;
      width: 74px;
      height: 74px;
      opacity: 0; } }
  /*
      ==============
         Navigation
      ==============
  */
  .mh-nav {
    width: 100%; }
  
  .mh-header {
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -webkit-transition: 0.3s;
    transition: 0.3s; }
    .mh-header.nav-strict {
      padding: 15px 0;
      -moz-transition: 0.3s;
      -o-transition: 0.3s;
      -webkit-transition: 0.3s;
      transition: 0.3s; }
    .mh-header .navbar {
      background-color: rgba(0, 0, 0, 0.3);
    }
    .mh-header .navbar-nav li {
      margin: 0 20px; }
      .mh-header .navbar-nav li a {
        border-bottom: 1px solid transparent;
        font-weight: bold;
        font-family: "roboto", sans-serif;
        font-size: 18px;
        opacity: 1;
        line-height: 30px; }
        .mh-header .navbar-nav li a.nav-link {
          padding-left: 0;
          padding-right: 0; }
        .mh-header .navbar-nav li a:hover {
          color: #0bceaf; }
          
  .home-padding {
    padding: 180px 0; }
  
  /*
      ==============
         Navigation
      ==============
  */
  .mh-home-2 .mh-header-info {
    text-align: center;
    padding-top: 100px; }
    .mh-home-2 .mh-header-info .hero-img {
      margin-bottom: 30px; }
      .mh-home-2 .mh-header-info .hero-img img {
        border: 0px solid #fff;
        border-radius: 50%; }
    .mh-home-2 .mh-header-info h2 {
      font-size: 50px;
      font-weight: 600;
      line-height: 60px;
      margin-top: 10px;
      margin-bottom: 0px;
      opacity: 0.9; }
    .mh-home-2 .mh-header-info h4 {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 20px;
      opacity: 0.9; }
    .mh-home-2 .mh-header-info p {
      padding: 0 120px;
      font-weight: 300; }
    .mh-home-2 .mh-header-info ul li {
      margin: 10px 6px; }
    .mh-home-2 .mh-header-info ul.mh-home-contact {
      margin-bottom: 15px; }
      .mh-home-2 .mh-header-info ul.mh-home-contact li {
        display: inline-block;
        margin: 10px 15px; }
        .mh-home-2 .mh-header-info ul.mh-home-contact li .fa {
          margin-right: 10px; }
        .mh-home-2 .mh-header-info ul.mh-home-contact li:hover .fa {
          color: #0bceaf; }
    .mh-home-2 .mh-header-info .mh-about-tag {
      width: 60%;
      margin: 0 auto; }
      .mh-home-2 .mh-header-info .mh-about-tag ul li {
        margin: 6px; }
  
  /*
      ==============
         HOME
      ==============
  */
  
  .mh-rtl .mh-home .mh-header-info ul li .fa {
      margin: 0 10px;
  }
  .mh-home .mh-header-info {
    padding-top: 100px; }
    .mh-home .mh-header-info .mh-promo {
      margin-bottom: 30px; }
      .mh-home .mh-header-info .mh-promo span {
        background-color: #0bceaf;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 1px;
        font-weight: bold;
        padding: 10px 30px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 0px; }
    .mh-home .mh-header-info h2 {
      font-size: 60px;
      font-weight: 600;
      line-height: 65px;
      margin-top: 10px;
      margin-bottom: 10px; }
    .mh-home .mh-header-info h4 {
      font-size: 26px;
      line-height: 34px;
      margin-bottom: 30px; }
    .mh-home .mh-header-info ul {
      margin-top: 20px; }
      .mh-home .mh-header-info ul li {
        margin: 10px 0; }
        .mh-home .mh-header-info ul li .fa {
          margin-right: 15px;
          display: inline-block; }
        .mh-home .mh-header-info ul li address {
          display: inline-block; }
        .mh-home .mh-header-info ul li:hover .fa {
          color: #0bceaf; }
    .mh-home .mh-header-info .social-icon {
      margin-top: 0; }
      .mh-home .mh-header-info .social-icon li .fa {
        font-size: 18px;
        line-height: 30px;
        padding: 4px 14px;
        margin-right: 10px;
        -moz-transition: 0.3s;
        -o-transition: 0.3s;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        border-radius: 3px; }
        .mh-home .mh-header-info .social-icon li .fa:hover {
          -moz-transition: 0.3s;
          -o-transition: 0.3s;
          -webkit-transition: 0.3s;
          transition: 0.3s; }
      .mh-home .mh-header-info .social-icon li a {
        font-size: 25px;
        text-align: center;
        width: 30px;
        height: 30px;
        padding: 0;
        margin-right: 0;
        border-radius: 50%; }
  .mh-home .hero-img {
    margin: 0 auto;
    float: none;
    margin-top: 30px;
    text-align: center; }
    .mh-home .hero-img img {
      margin: 0 auto;
      float: none;
      text-align: center;
      border: 20px solid rgba(0, 0, 0, 0.17);
      border-radius: 50%; }
  .mh-home .img-border {
    height: 400px;
    width: 400px;
    border: 20px solid rgba(0, 0, 0, 0.07);
    margin: 0 auto;
    border-radius: 50%; }
  
  .mh-featured-project-img img {
    width: inherit !important;
    margin: 0 auto; }
  
  .mh-single-project-slide-by-side {
    margin: 0 auto; }
  
  /*
      ==============
         ABOUT
      ==============
  */
  .mh-about .mh-about-inner {
    padding-left: 50px; }
    .mh-about .mh-about-inner h2 {
      margin-bottom: 20px;
      margin-top: 20px; }
    .mh-about .mh-about-inner p {
      margin-bottom: 30px; }
    .mh-about .mh-about-inner .btn {
      margin-top: 20px; }
      .mh-about .mh-about-inner .btn .fa {
        margin-left: 10px; }
  
  .mh-about-tag {
    word-break: break-all;
    margin-bottom: 10px;
    margin-top: 10px; }
    .mh-about-tag ul li {
      margin: 0px 6px;
      border-radius: 4px;
      margin-bottom: 10px;
      padding: 4px 0px;
      display: inline-block; }
      .mh-about-tag ul li span {
        border: 1px solid #0bceaf;
        padding: 4px 10px;
        border-radius: 4px; }
        .mh-about-tag ul li span:hover {
          background-color: transparent !important; }
  
  /*
      ==============
         SKILLS
      ==============
  */
  .mh-skills .mh-professional-skill {
    padding-right: 60px; }
    .mh-skills .mh-professional-skill h3 {
      font-size: 30px;
      line-height: 40px;
      text-align: center;
      font-weight: bold;
      margin-bottom: 60px; }
  
  .mh-professional-skills {
    padding-left: 20%; }
    .mh-professional-skills h3 {
      font-size: 30px;
      line-height: 40px;
      text-align: center;
      font-weight: bold;
      margin-bottom: 60px; }
  
  .mh-professional-progress li {
    display: inline-block;
    margin: 0 auto;
    float: none;
    width: 48%;
    margin-bottom: 30px;
    text-align: center; }
  
  .mh-progress {
    margin-bottom: 10px;
    font: 900 1.1428571429em/1 Cinzel, cursive; }
    .mh-progress .progressbar-text {
      color: inherit !important; }
  
  .progress.progress-line {
    height: 10px;
    margin-right: 60px; }
  
  .progress.progress-line .progressbar-text {
    position: absolute;
    top: 50%;
    left: 100%;
    width: 60px;
    -webkit-transform: translateY(-35%);
    -ms-transform: translateY(-35%);
    transform: translateY(-35%);
    text-align: center; }
  
  .mh-progress.mh-progress-circle {
    display: inline-block;
    width: 100px;
    height: 100px; }
  
  .mh-progress path:nth-child(1) {
    stroke: rgba(0, 0, 0, 0.7); }
  
  .mh-progress path:nth-child(2) {
    stroke: #0bceaf; }
  
  .candidatos {
    position: relative;
    margin-bottom: 15px; }
    .candidatos .parcial {
      display: inline-block;
      width: 100%;
      vertical-align: middle; }
      .candidatos .parcial .info {
        position: relative; }
        .candidatos .parcial .info .nome {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 15px;
          font-weight: 600;
          opacity: 0.9; }
        .candidatos .parcial .info .eleito, .candidatos .parcial .info .segundo-turno {
          padding: 0 5px 2px 5px;
          border-radius: 2px; }
        .candidatos .parcial .info .percentagem-num {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 14px;
          font-weight: normal; }
      .candidatos .parcial .progressBar {
        position: relative;
        width: 100%;
        height: 7px;
        margin: 30px 0 2px;
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.5); }
      .candidatos .parcial .percentagem {
        position: absolute;
        top: 0;
        left: 0;
        height: 7px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        background: #0bceaf;
        -webkit-transition: 3s all;
        -webkit-animation-duration: 3s;
        -webkit-animation-name: animationProgress; }
      .candidatos .parcial .partidas {
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase; }
  
  .percentual {
    animation-name: animationProgress; }
  
  @-webkit-keyframes animationProgress {
    from {
      width: 0; } }
  @keyframes animationProgress {
    from {
      width: 0; } }
  /*
      ==============
        EXPERIENCES
      ==============
  */
  .mh-experince h3 {
    font-size: 30px;
    line-height: 40px;
    font-weight: bold;
    margin-bottom: 60px; }
  .mh-experince .mh-education-deatils .mh-education-item {
    margin-bottom: 30px;
    padding: 26px 30px;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); }
    .mh-experince .mh-education-deatils .mh-education-item:last-child {
      margin-bottom: 0; }
    .mh-experince .mh-education-deatils .mh-education-item h4 {
      font-size: 20px;
      line-height: 30px;
      font-weight: 600; }
      .mh-experince .mh-education-deatils .mh-education-item h4 a {
        font-style: italic;
        margin-left: 4px;
        color: #0bceaf; }
    .mh-experince .mh-education-deatils .mh-education-item .mh-eduyear {
      margin: 10px 0;
      color: #0bceaf; }
    .mh-experince .mh-education-deatils .mh-education-item p {
      font-size: 14px;
      line-height: 23px;
      margin-bottom: 0; }
  
  /*
      ==============
        EDUCATION
      ==============
  */
  .mh-education {
    padding-right: 30px; }
  
  .mh-work {
    padding-left: 30px; }
    .mh-work h3 {
      font-size: 30px;
      line-height: 40px;
      font-weight: bold;
      margin-bottom: 60px; }
    .mh-work .mh-experience-deatils .mh-work-item {
      margin-bottom: 30px;
      padding: 20px 30px;
      border-radius: 4px;
      -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
      -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); }
      .mh-work .mh-experience-deatils .mh-work-item:last-child {
        margin-bottom: 0; }
      .mh-work .mh-experience-deatils .mh-work-item h4 {
        font-size: 20px;
        line-height: 30px;
        font-weight: 600; }
        .mh-work .mh-experience-deatils .mh-work-item h4 a {
          color: #0bceaf; }
      .mh-work .mh-experience-deatils .mh-work-item .mh-eduyear {
        margin: 10px 0;
        color: #0bceaf; }
      .mh-work .mh-experience-deatils .mh-work-item span {
        font-size: 12px;
        line-height: 22px; }
      .mh-work .mh-experience-deatils .mh-work-item .work-responsibility li {
        font-size: 14px;
        line-height: 23px;
        margin: 2px 0; }
        .mh-work .mh-experience-deatils .mh-work-item .work-responsibility li .fa {
          font-size: 10px;
          line-height: 23px;
          margin-right: 10px; }
  
  /*
      ==============
        Portfolio
      ==============
  */
  .portfolio-nav {
    margin: 0 auto;
    margin-bottom: 40px; }
    .portfolio-nav ul {
      margin: 0 auto;
      float: none;
      text-align: center; }
      .portfolio-nav ul li {
        display: inline-block;
        margin: 0 10px;
        cursor: pointer; }
        .portfolio-nav ul li:hover {
          color: #0bceaf; }
        .portfolio-nav ul li.active {
          color: #0bceaf; }
  
  .mh-portfolio-modal {
    display: none;
    background: #282828; }
  
  .mh-portfolio-modal-inner {
    max-height: 500px;
    overflow-y: scroll;
    margin-right: 20px; }
    .mh-portfolio-modal-inner::-webkit-scrollbar-track {
      border: 1px solid #000;
      padding: 2px 0;
      background-color: #fff; }
    .mh-portfolio-modal-inner::-webkit-scrollbar {
      width: 10px; }
    .mh-portfolio-modal-inner::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 10px #000; }
    .mh-portfolio-modal-inner h2 {
      margin-top: 10px;
      margin-bottom: 20px; }
  
  .mh-portfolio-modal-img img {
    margin-bottom: 20px; }
  
  .mix {
    display: none; }
  
  /*
      ==============
         Pricing
      ==============
  */
  .mh-pricing .mh-pricing {
    padding: 30px 0;
    text-align: center;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    margin-bottom: 30px; }
    .mh-pricing .mh-pricing:hover {
      -moz-transition: 0.3s;
      -o-transition: 0.3s;
      -webkit-transition: 0.3s;
      transition: 0.3s; }
      .mh-pricing .mh-pricing:hover .btn {
        -moz-transition: 0.3s;
        -o-transition: 0.3s;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        background-color: #0ab69a; }
    .mh-pricing .mh-pricing .fa {
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 20px;
      color: #7af8e4; }
    .mh-pricing .mh-pricing h4 {
      margin-bottom: 10px;
      margin-top: 20px; }
    .mh-pricing .mh-pricing h5 {
      font-size: 36px;
      line-height: 40px;
      color: #0bceaf;
      margin-bottom: 20px;
      margin-top: 20px; }
    .mh-pricing .mh-pricing span {
      opacity: 0.8; }
    .mh-pricing .mh-pricing ul {
      margin: 30px 0; }
      .mh-pricing .mh-pricing ul li {
        margin: 2px 0; }
    .mh-pricing .mh-pricing .btn {
      -moz-transition: 0.3s;
      -o-transition: 0.3s;
      -webkit-transition: 0.3s;
      transition: 0.3s; }
  
  /*
      ==============
         BLOG
      ==============
  */
  .mh-blog .mh-blog-item {
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border-radius: 10px 10px 0px 0px;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    margin-bottom: 30px; }
    .mh-blog .mh-blog-item:hover {
      -moz-transition: 0.3s;
      -o-transition: 0.3s;
      -webkit-transition: 0.3s;
      transition: 0.3s; }
    .mh-blog .mh-blog-item .blog-inner {
      padding: 0px 20px;
      padding-bottom: 30px; }
      .mh-blog .mh-blog-item .blog-inner h2 a {
        color: #fff; }
      .mh-blog .mh-blog-item .blog-inner a {
        color: #0bceaf; }
    .mh-blog .mh-blog-item img {
      margin-bottom: 30px; }
    .mh-blog .mh-blog-item h2 {
      font-size: 24px;
      line-height: 32px;
      font-weight: 600;
      margin-bottom: 10px;
      color: #fff; }
      .mh-blog .mh-blog-item h2 a {
        color: #0bceaf; }
        .mh-blog .mh-blog-item h2 a:hover {
          color: #0ac4a7; }
  
  /*
      ==============
         MH VIDEO
      ==============
  */
  .mh-video .each-video {
    padding-top: 30px;
    padding-bottom: 30px; }
    .mh-video .each-video p {
      margin-top: 20px;
      padding: 0 160px; }
    .mh-video .each-video .fa {
      background-color: #fff;
      border-radius: 50%;
      color: black;
      height: 40px;
      width: 40px;
      margin-top: 20px;
      line-height: 40px;
      text-align: center; }
  
  /*
      ==============
         QUATES
      ==============
  */
  .mh-quates .each-quates {
    padding-top: 30px;
    padding-bottom: 30px; }
    .mh-quates .each-quates p {
      margin-top: 20px; }
    .mh-quates .each-quates .fa {
      background-color: #fff;
      border-radius: 50%;
      color: black;
      height: 40px;
      width: 40px;
      margin-top: 20px;
      line-height: 40px;
      text-align: center; }
  
  /*
      ==============
         VIDEO
      ==============
  */
  .mh-video .each-video {
    padding: 30px 0; }
    .mh-video .each-video p {
      margin-top: 20px; }
    .mh-video .each-video .fa {
      background-color: #fff;
      border-radius: 50%;
      color: black;
      height: 40px;
      width: 40px;
      margin-top: 20px;
      line-height: 40px;
      text-align: center; }
  
  /*
      =================
         TESTIMONIAL
      =================
  */
  .mh-testimonial.mh-single-testimonial .mh-client-item p {
    padding: 0 160px;
    margin: 60px 0; }
  .mh-testimonial.mh-2-testimonial .mh-client-item p {
    padding: 0 0px;
    margin: 20px 0; }
  .mh-testimonial .mh-client-item {
    margin: 0 auto;
    float: none;
    text-align: center;
    padding: 30px;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); }
    .mh-testimonial .mh-client-item img {
      width: inherit;
      float: none;
      margin: 0 auto;
      border: 5px solid #0bceaf;
      margin: 0 auto;
      margin-bottom: 20px;
      border-radius: 50%; }
    .mh-testimonial .mh-client-item p {
      margin: 10px 0; }
      .mh-testimonial .mh-client-item p::before {
        content: open-quote;
        margin-right: 6px;
        font-size: 20px; }
      .mh-testimonial .mh-client-item p::after {
        content: close-quote;
        margin-left: 6px;
        font-size: 20px; }
    .mh-testimonial .mh-client-item h4 {
      font-size: 16px;
      line-height: 25px;
      margin-top: 30px;
      margin-bottom: 0; }
    .mh-testimonial .mh-client-item span {
      font-size: 14px;
      line-height: 24px; }
  .mh-testimonial .each-client-item {
    margin: 30px 20px; }
  .mh-testimonial .owl-controls .owl-nav {
    display: none !important; }
  .mh-testimonial .owl-controls .owl-dots {
    padding-left: 15px;
    margin-top: 30px;
    text-align: center; }
  .mh-testimonial .owl-controls .owl-dots .owl-dot {
    height: 15px;
    width: 15px;
    border: 1px solid #0bceaf;
    display: inline-block;
    border-radius: 50%;
    margin-right: 10px; }
  .mh-testimonial .owl-controls .owl-dots .owl-dot.active {
    background-color: #0bceaf; }
  
  /*
      ==============
        FOOTER ONE
      ==============
  */
  .mh-footer ::placeholder {
    opacity: 0.9; }
  .mh-footer .form-control {
    padding: 20px;
    -webkit-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
    -moz-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
    box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8); }
    .mh-footer .form-control:focus {
      -webkit-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
      -moz-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
      box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
      border-color: transparent; }
  .mh-footer .contact-message {
    -webkit-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
    -moz-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
    box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
    background-color: transparent;
    border-color: transparent;
    border-radius: 4px;
    margin-bottom: 20px;
    width: 100%;
    padding: 20px;
    outline: 0; }
    .mh-footer .contact-message:focus {
      -webkit-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
      -moz-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
      box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
      border-color: transparent; }
  
  /*
      ==============
         MAP
      ==============
  */
  .mh-map {
    padding: 0px;
    margin-top: 10px; }
    .mh-map #mh-map {
      height: 400px;
      width: 100%; }
  
  /*
      ============
      Footer Address
      ==============
  */
  .mh-footer-address {
    margin-bottom: 60px; }
    .mh-footer-address .mh-address-footer-item {
      padding: 30px;
      text-align: center;
      margin-bottom: 30px; }
      .mh-footer-address .mh-address-footer-item h4 {
        margin-bottom: 20px; }
      .mh-footer-address .mh-address-footer-item address {
        margin-bottom: 0; }
      .mh-footer-address .mh-address-footer-item .each-icon {
        margin-bottom: 30px; }
        .mh-footer-address .mh-address-footer-item .each-icon .fa {
          text-align: center;
          width: 100px;
          height: 100px;
          border: 1px solid;
          border-radius: 50%;
          line-height: 100px;
          font-size: 20px;
          border-color: #0bceaf; }
  
  /*
  ====================
      Subpage
  ====================
  */
  .mh-page-title {
    padding-top: 100px; }
    .mh-page-title h2 {
      font-size: 40px;
      line-height: 46px;
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 10px; }
  
  .nav-strict {
    z-index: 99;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); }
  
  /* .mh-fixed-nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0; } */
  
  /*
  ====================
      FOOTER 3
  ====================
  */
  .mh-footer-2 .form-control {
    -webkit-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
    -moz-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
    box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
    background-color: transparent;
    border-color: transparent;
    margin-bottom: 20px;
    height: 50px;
    line-height: 50px; }
    .mh-footer-2 .form-control:focus {
      -webkit-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
      -moz-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
      box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
      background-color: transparent; }
  .mh-footer-2 .contact-message {
    -webkit-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
    -moz-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
    box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8); }
    .mh-footer-2 .contact-message:focus {
      -webkit-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
      -moz-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
      box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
      background-color: transparent; }
  
  .mh-copyright {
    margin-top: 60px; }
  
  /*
  ==================
      FOOTER 3
  =================
  */
  .mh-footer-3 .mh-footer-address .mh-address-footer-item {
    padding: 10px 30px;
    text-align: center;
    margin-bottom: 15px; }
    .mh-footer-3 .mh-footer-address .mh-address-footer-item h4 {
      margin-bottom: 10px;
      margin-top: 10px; }
    .mh-footer-3 .mh-footer-address .mh-address-footer-item .each-info {
      padding-left: 40px;
      text-align: left; }
  .mh-footer-3 .form-control {
    -webkit-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
    -moz-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
    box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
    background-color: transparent;
    border-color: transparent;
    margin-bottom: 20px;
    height: 50px;
    line-height: 50px; }
    .mh-footer-3 .form-control:focus {
      -webkit-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
      -moz-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
      box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
      background-color: transparent; }
  .mh-footer-3 .contact-message {
    -webkit-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
    -moz-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
    box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8); }
    .mh-footer-3 .contact-message:focus {
      -webkit-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
      -moz-box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
      box-shadow: 0 0px 2px 0 rgba(255, 255, 255, 0.8), 0 0px 1px 1px rgba(255, 255, 255, 0.8);
      background-color: transparent; }
  
  /*
  ====================
     PORTFOLIO
  ===================
  */
  .grid-item {
    margin-bottom: 20px; }
  
  .portfolio-nav {
    text-align: center;
    margin-bottom: 50px; }
    .portfolio-nav ul li {
      display: inline-block;
      margin: 0 20px;
      cursor: pointer; }
      .portfolio-nav ul li span {
        padding: 15px 0;
        border-bottom: 2px solid transparent; }
      .portfolio-nav ul li.current span {
        border-bottom: 2px solid #0bceaf; }
  
  .mh-project-gallery .grid-item figure {
    position: relative;
    overflow: hidden;
    border-radius: 10px; }
    .mh-project-gallery .grid-item figure img {
      position: relative;
      width: 100%;
      display: block; }
    .mh-project-gallery .grid-item figure figcaption {
      position: absolute;
      padding: 15px;
      height: 100%;
      width: 100%;
      top: auto;
      left: 0;
      color: #fff;
      bottom: 0;
      z-index: 10;
      -moz-transition: 0.5s;
      -o-transition: 0.5s;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
      .mh-project-gallery .grid-item figure figcaption:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        -webkit-transition: all 0.4s ease-out;
        -moz-transition: all 0.4s ease-out;
        -o-transition: all 0.4s ease-out;
        -ms-transition: all 0.4s ease-out;
        transition: all 0.4s ease-out;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(0.95);
        -o-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(0.95);
        -ms-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(0.95);
        -moz-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(0.95);
        -webkit-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(0.95);
        z-index: -1;
        opacity: 0; }
      .mh-project-gallery .grid-item figure figcaption .fa {
        margin: 0;
        padding: 0;
        font-size: 24px;
        font-weight: 400;
        line-height: 35px;
        position: absolute;
        text-align: center;
        width: 100%;
        left: 0;
        top: 45%;
        margin-top: -22px;
        transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg) scale(1);
        -o-transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg) scale(1);
        -ms-transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg) scale(1);
        -moz-transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg) scale(1);
        -webkit-transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg) scale(1);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        opacity: 0;
        letter-spacing: 0px; }
      .mh-project-gallery .grid-item figure figcaption .title {
        margin: 0;
        padding: 0;
        position: absolute;
        text-align: center;
        width: 100%;
        left: 0;
        right: 0;
        font-size: 18px;
        line-height: 30px;
        text-transform: capitalize;
        top: 50%;
        margin-top: 30px;
        margin-top: -2px;
        transform: translateX(80px) translateY(0) translateZ(0) rotate(0deg) scale(1);
        -o-transform: translateX(80px) translateY(0) translateZ(0) rotate(0deg) scale(1);
        -ms-transform: translateX(80px) translateY(0) translateZ(0) rotate(0deg) scale(1);
        -moz-transform: translateX(80px) translateY(0) translateZ(0) rotate(0deg) scale(1);
        -webkit-transform: translateX(80px) translateY(0) translateZ(0) rotate(0deg) scale(1);
        -webkit-transition: all 0.4s ease-out;
        -moz-transition: all 0.4s ease-out;
        -o-transition: all 0.4s ease-out;
        -ms-transition: all 0.4s ease-out;
        transition: all 0.4s ease-out;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        opacity: 0;
        letter-spacing: 0px; }
      .mh-project-gallery .grid-item figure figcaption .sub-title {
        margin: 0;
        padding: 0;
        position: absolute;
        text-align: center;
        width: 100%;
        left: 0;
        font-size: 13px;
        top: 50%;
        margin-top: 30px;
        transform: translateX(-70px) translateY(0) translateZ(0) rotate(0deg) scale(1);
        -o-transform: translateX(-70px) translateY(0) translateZ(0) rotate(0deg) scale(1);
        -ms-transform: translateX(-70px) translateY(0) translateZ(0) rotate(0deg) scale(1);
        -moz-transform: translateX(-70px) translateY(0) translateZ(0) rotate(0deg) scale(1);
        -webkit-transform: translateX(-70px) translateY(0) translateZ(0) rotate(0deg) scale(1);
        -moz-transition: 0.5s;
        -o-transition: 0.5s;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        opacity: 0;
        letter-spacing: 0px; }
      .mh-project-gallery .grid-item figure figcaption a {
        position: absolute;
        z-index: 1111;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        text-indent: -99999px; }
    .mh-project-gallery .grid-item figure:hover {
      -webkit-transition: all 0.4s ease-out;
      -moz-transition: all 0.4s ease-out;
      -o-transition: all 0.4s ease-out;
      -ms-transition: all 0.4s ease-out;
      transition: all 0.4s ease-out;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
      .mh-project-gallery .grid-item figure:hover figcaption {
        color: #fff;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background: rgba(11, 206, 175, 0.9); }
        .mh-project-gallery .grid-item figure:hover figcaption:after {
          opacity: 1;
          transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
          -o-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
          -ms-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
          -moz-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
          -webkit-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
          -webkit-transition: all 0.4s ease-out;
          -moz-transition: all 0.4s ease-out;
          -o-transition: all 0.4s ease-out;
          -ms-transition: all 0.4s ease-out;
          transition: all 0.4s ease-out;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale; }
        .mh-project-gallery .grid-item figure:hover figcaption .fa {
          transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg) scale(1);
          -o-transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg) scale(1);
          -ms-transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg) scale(1);
          -moz-transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg) scale(1);
          -webkit-transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg) scale(1);
          -moz-transition: 0.2s;
          -o-transition: 0.2s;
          -webkit-transition: 0.2s;
          transition: 0.2s;
          opacity: 1; }
        .mh-project-gallery .grid-item figure:hover figcaption .title {
          transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg) scale(1);
          -o-transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg) scale(1);
          -ms-transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg) scale(1);
          -moz-transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg) scale(1);
          -webkit-transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg) scale(1);
          -moz-transition: 0.4s;
          -o-transition: 0.4s;
          -webkit-transition: 0.4s;
          transition: 0.4s;
          opacity: 1; }
        .mh-project-gallery .grid-item figure:hover figcaption .sub-title {
          transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg) scale(1);
          -o-transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg) scale(1);
          -ms-transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg) scale(1);
          -moz-transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg) scale(1);
          -webkit-transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg) scale(1);
          -moz-transition: 0.6s;
          -o-transition: 0.6s;
          -webkit-transition: 0.6s;
          transition: 0.6s;
          opacity: 1; }
  
  .home-ov-img {
    background-image: url(../../public/images/header-bg-2.png);
    background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0 auto;
    background-position: center center; }
  
  .mh-featured-project .mh-featured-item {
    margin: 30px 0; }
    .mh-featured-project .mh-featured-item .mh-featured-project-content .project-category {
      font-size: 14px;
      color: #0bceaf;
      margin-top: 0;
      margin-bottom: 0;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px; }
    .mh-featured-project .mh-featured-item .mh-featured-project-content h2 {
      letter-spacing: 2px;
      margin-top: 12px;
      margin-bottom: 12px;
      text-transform: uppercase; }
    .mh-featured-project .mh-featured-item .mh-featured-project-content span {
      text-transform: uppercase; }
    .mh-featured-project .mh-featured-item .mh-featured-project-content p {
      text-align: left;
      margin-top: 10px;
      margin-bottom: 20px; }
    .mh-featured-project .mh-featured-item .mh-featured-project-content .mh-testimonial blockquote {
      padding: 0;
      margin: 0;
      margin-top: 30px; }
      .mh-featured-project .mh-featured-item .mh-featured-project-content .mh-testimonial blockquote q {
        font-style: italic; }
        .mh-featured-project .mh-featured-item .mh-featured-project-content .mh-testimonial blockquote q::before {
          content: open-quote; }
        .mh-featured-project .mh-featured-item .mh-featured-project-content .mh-testimonial blockquote q::after {
          content: close-quote; }
      .mh-featured-project .mh-featured-item .mh-featured-project-content .mh-testimonial blockquote cite {
        display: block;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        margin-top: 10px; }
  
  .mh-single-project-slide .owl-controls .owl-nav {
    display: none !important; }
  .mh-single-project-slide .owl-controls .owl-dots {
    padding-left: 15px;
    margin-top: 30px;
    text-align: center; }
  .mh-single-project-slide .owl-controls .owl-dots .owl-dot {
    height: 15px;
    width: 15px;
    border: 1px solid #0bceaf;
    display: inline-block;
    border-radius: 50%;
    margin-right: 10px; }
  .mh-single-project-slide .owl-controls .owl-dots .owl-dot.active {
    background-color: #0bceaf; }
  
  .mh-single-project-slide-by-side .owl-next {
    position: absolute;
    border-radius: 50%;
    top: 48%;
    height: 50px;
    width: 50px;
    right: -10%; }
  .mh-single-project-slide-by-side .owl-next .fa {
    font-size: 40px;
    margin-left: 15px; }
  .mh-single-project-slide-by-side .owl-prev {
    position: absolute;
    border-radius: 50%;
    top: 48%;
    left: -10%;
    height: 50px;
    width: 50px; }
  .mh-single-project-slide-by-side .owl-prev .fa {
    font-size: 40px;
    margin-left: 10px; }
  
  .section-title h3 {
    margin-bottom: 60px; }
  .section-title h2 {
    margin-bottom: 60px; }
  
  /*
      ==============
         SERVICES
      ==============
  */
  .jv-content .jv-content-item {
    padding: 20px; 
    border-radius: 20px;
    cursor: pointer;
    min-height: 150px;}
    .jv-content .jv-content-item .fa {
      font-size: 30px;
      margin-bottom: 20px;
      margin-top: 16px; }
    .jv-content .jv-content-item h3 h2 {
      margin-bottom: 18px;
      font-size: 25px;
      line-height: 34px; }
    .jv-content .jv-content-item img {
      width: 30%; }
    .jv-content .jv-content-item a {
      color: #0bceaf; }
      .jv-content .jv-content-item a .fa {
        font-size: 16px;
        line-height: 27px;
        margin-left: 6px; }
  /*
  =================
  BLOG Pagination
  =============
  */
  .mh-pagination {
    margin: 40px 0; }
    .mh-pagination ul li {
      margin: 0 10px; }
    .mh-pagination .page-link {
      position: relative;
      display: block;
      padding: 12px 16px;
      margin-left: -1px;
      border-radius: 50%;
      line-height: 14px;
      background-color: transparent;
      border: 1px solid #fff;
      font-weight: bold; }
      .mh-pagination .page-link:hover {
        color: #0bceaf; }
    .mh-pagination .page-item:last-child .page-link {
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%; }
    .mh-pagination .page-item:first-child .page-link {
      margin-left: 0;
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%; }
  
  .mh-blog-sidebar {
    padding: 0 20px; }
  
  .sidebar-item {
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    margin-bottom: 30px;
    padding: 20px 10px; }
    .sidebar-item h3 {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 10px; }
  
  .mh-author-info {
    text-align: center; }
    .mh-author-info h2 {
      font-size: 24px;
      line-height: 34px;
      margin: 0; }
    .mh-author-info h4 {
      font-size: 14px;
      margin: 0;
      line-height: 24px;
      font-weight: normal;
      margin-bottom: 10px; }
    .mh-author-info .mh-author-img {
      margin: 0 auto;
      margin-bottom: 10px;
      float: none;
      text-align: center; }
      .mh-author-info .mh-author-img img {
        margin: 0 auto;
        text-align: center;
        border: 10px solid rgba(0, 0, 0, 0.17);
        border-radius: 50%; }
    .mh-author-info .img-border {
      height: 130px;
      width: 130px;
      border: 10px solid rgba(0, 0, 0, 0.07);
      margin: 0 auto;
      border-radius: 50%; }
    .mh-author-info .social-icon li a:hover {
      color: #0bceaf; }
  
  .mh-blog-category h4 {
    margin: 0;
    margin-bottom: 10px; }
  .mh-blog-category ul li {
    display: block;
    margin: 2px 0; }
    .mh-blog-category ul li a:hover {
      color: #0bceaf; }
  
  .mh-blog-insta ul li {
    width: 48%;
    display: inline-block;
    margin-bottom: 10px; }
    .mh-blog-insta ul li img {
      padding-right: 4px; }
  
  .mh-blog-post-info {
    margin-bottom: 10px; }
    .mh-blog-post-info ul li {
      display: inline-block;
      margin: 0 6px;
      margin-left: 0; }
      .mh-blog-post-info ul li strong {
        margin-right: 6px; }
  
  .mh-blog .mh-blog-item.blog-single h2 {
    font-size: 36px;
    line-height: 40px;
    font-weight: bold; }
  .mh-blog .mh-blog-item.blog-single h3 {
    margin-bottom: 10px; }
  .mh-blog .mh-blog-item.blog-single ul {
    padding-left: 30px;
    margin-bottom: 20px; }
    .mh-blog .mh-blog-item.blog-single ul li {
      list-style-type: circle; }
  
  .mh-blog-next-prev-post a {
    color: #0bceaf; }
    .mh-blog-next-prev-post a .fa {
      margin: 0 4px; }
  
  .shadow-one {
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); }
  
  .mh-related-post {
    padding: 30px;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    margin: 0;
    margin-bottom: 30px; }
  
  .blog-form-inner {
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    padding: 30px; }
    .blog-form-inner h3 {
      margin-top: 0px;
      margin-bottom: 20px;
      font-size: 26px;
      line-height: 34px; }
  
  .comments {
    margin-top: 20px;
    overflow: hidden;
    margin-bottom: 30px; }
  
  .comment-deatils span {
    color: #0bceaf; }
  
  .single-comment {
    overflow: hidden;
    margin-top: 30px;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); }
  
  .post-comment {
    margin-top: 30px;
    padding: 30px; }
  
  #msgSubmit {
    margin-top: 20px;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 30px; }

  .list-group-flush > .list-group-item {
      text-align: left; }
  /*# sourceMappingURL=styles.css.map */
  
  