.new .navbar-toggler .icon, .new .navbar-toggler .icon::after, .new .navbar-toggler .icon::before {
  background-color: #0092ca; }

.white-vertion .page-item.active .page-link {
  color: #0092ca; }

.dark-vertion .page-item.active .page-link {
  color: #0092ca; }

.mh-header .navbar-nav li a:hover {
  color: #0092ca; }

.mh-home-2 .mh-header-info ul.mh-home-contact li:hover .fa {
  color: #0092ca; }

.mh-home .mh-header-info .mh-promo span {
  background-color: #0092ca; }
.mh-home .mh-header-info ul li:hover .fa {
  color: #0092ca; }

.mh-about-tag ul li span {
  border: 1px solid #0092ca; }
  .mh-about-tag ul li span:hover {
    background-color: #0092ca; }

.mh-progress path:nth-child(2) {
  stroke: #0092ca; }

.candidatos .parcial .percentagem {
  background: #0092ca; }

.mh-experince .mh-education-deatils .mh-education-item .mh-eduyear {
  color: #0092ca; }

.mh-work .mh-experience-deatils .mh-work-item .mh-eduyear {
  color: #0092ca; }

.mh-experince .mh-education-deatils .mh-education-item h4 a {
  color: #0092ca; }

.mh-work .mh-experience-deatils .mh-work-item h4 a {
  color: #0092ca; }

.portfolio-nav ul li:hover {
  color: #0092ca; }
.portfolio-nav ul li.active {
  color: #0092ca; }

.mh-pricing .mh-pricing:hover .btn {
  background-color: #0d8aee; }
.mh-pricing .mh-pricing .fa {
  color: #b2dbfb; }
.mh-pricing .mh-pricing h5 {
  color: #0092ca; }

.mh-blog .mh-blog-item .blog-inner h2 a {
  color: #fff; }
.mh-blog .mh-blog-item .blog-inner a {
  color: #0092ca; }
.mh-blog .mh-blog-item h2 {
  color: #0092ca; }
  .mh-blog .mh-blog-item h2 a {
    color: #0092ca; }
    .mh-blog .mh-blog-item h2 a:hover {
      color: #1791f2; }

.mh-testimonial .mh-client-item img {
  border: 5px solid #0092ca; }
.mh-testimonial .owl-controls .owl-dots .owl-dot {
  border: 1px solid #0092ca; }
.mh-testimonial .owl-controls .owl-dots .owl-dot.active {
  background-color: #0092ca; }

.mh-footer-address .mh-address-footer-item .each-icon .fa {
  border-color: #0092ca; }

.old .navbar-collapse {
  background-color: #0092ca; }
.old .navbar-header .navbar-toggler .icon-bar {
  background-color: #0092ca; }
.old .navbar-collapse {
  background-color: #0092ca; }

.new .navbar-collapse {
  background-color: #0092ca; }
.new .navbar-header .navbar-toggler .icon-bar {
  background-color: #0092ca; }
.new .navbar-collapse {
  background-color: #0092ca; }

.portfolio-nav ul li.current span {
  border-bottom: 2px solid #0092ca; }

.mh-project-gallery .grid-item figure:hover figcaption {
  background: rgba(33, 150, 243, 0.9); }

.mh-featured-project .mh-featured-item .mh-featured-project-content .project-category {
  color: #0092ca; }

.mh-single-project-slide .owl-controls .owl-dots .owl-dot {
  border: 1px solid #0092ca; }
.mh-single-project-slide .owl-controls .owl-dots .owl-dot.active {
  background-color: #0092ca; }

.jv-content .jv-content-item a {
  color: #0092ca; }

.mh-pagination .page-link:hover {
  color: #0092ca; }

.mh-author-info .social-icon li a:hover  i{
  color: #0092ca  !important; }

  .social-icon li a:hover  i{
    color: #0092ca  !important; }
    
.mh-blog-category ul li a:hover {
  color: #0092ca !important; }

.mh-blog-next-prev-post a {
  color: #0092ca; }

.comment-deatils span {
  color: #0092ca; }

.btn.btn-fill {
  background-color: #0092ca; }

.mh-pricing .mh-pricing .fa {
  color: #0092ca; }

.section-title h6 {
  color: #0092ca; }
.section-title h5 {
  color: #0092ca; }
/*# sourceMappingURL=blue-munsell.css.map */
.list-group-flush > .list-group-item{
  background-color: transparent;
  border-color: #eeeeee;
}

.list-group-flush > .list-group-item:hover {
  background-color: #222831 !important;
}
.list-group-flush > .list-group-item:focus {
  background-color: #222831 !important;
}
.list-group-flush >  .list-group-item .badge {
  float: left;
}

.badge-danger {
  background-color: #da0037 !important;
}

.badge-primary {
  background-color: #0092ca !important;
}

